<template>
    <header class="fixed top-0 left-0 z-50 w-full  bg-white shadow-xl">
      <div class="xl:px-80 md:px-36  flex mx-auto py-4 items-center bg-bg">
        <nav class="ml-auto">
          <ul class="flex items-center md:space-x-8 space-x-4">
            <div class="textstyle flex items-center cursor-pointer">
              <img src="../assets/icons-04.svg" class="w-4 h-4" alt="">
              <a class="pl-2" :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
            </div>
            <div class=" textstyle cursor-pointer">
              <select class="bg-bg text-color1" v-model="selectedLanguage" @change="switchLanguage">
                <option class="text-sm" value="MN">МN</option>
                <option class="text-sm" value="EN">EN</option>
              </select>
            </div>
            <li class="cursor-pointer">
              <router-link to="/LoginHome" class="text-color1  xl:pr-36  pr-2 textstyle ">{{ translations.login }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="container mx-auto py-6 xl:flex lg:flex  items-center justify-between">
        <div class="flex items-center justify-between pt-4 pb-4 md:pt-0 md:pb-0 lg:px-4 xl:px-0">
          <div class="pl-4 md:pl-4 lg:pl-0">
            <a href="./"><img src="../assets/logo.svg" alt=""></a>
          </div>
          <button @click="toggleBurgerMenu" class="lg:hidden menuBtn mr-2">
            <div class="h-1 w-6 bg-black"></div>
            <div class="h-1 my-1 w-6 bg-black"></div>
            <div class="h-1 w-6 bg-black"></div>
          </button>
        </div>
        <div class="burger-menu" :class="{ 'open': isBurgerMenuOpen }">
          <nav class=" menu  xl:block  lg:text-right    md:mt-0 mt-4 items-center  font-Poppins text-color4 ">
            <ul class="relative xl:text-right md:text-right text-left ml-2  lg:pr-0 md:pr-2   lg:space-y-0 md:space-y-2 space-y-2 md:pt-4 pt-8 lg:z-40 lg:flex items-center md:justify-between xl:space-x-10 md:space-x-6  text-base not-italic font-semibold leading-normal">
              <li><router-link to="/" class="relative hover:scale-110 duration-300 inline-block border-b-4 border-color2 -mb-1 cursor-pointer">{{ translations.home }}</router-link></li>
              <li><router-link to="/location" class="menuEffect relative hover:scale-110 duration-300 inline-block cursor-pointer">{{ translations.location }}</router-link></li>
              <li><router-link to="/news" class="menuEffect relative hover:scale-110 duration-300 inline-block cursor-pointer">{{ translations.news }}</router-link></li>
              <li><router-link to="/helper" class="menuEffect relative hover:scale-110 duration-300 inline-block text-color cursor-pointer">{{ translations.help }}</router-link></li>
              <li><a href="https://www.ulaanbaatar-airport.mn/international-flights" target="_blank" class="menuEffect relative hover:scale-110 duration-300 inline-block ">{{ translations.schedule }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
</template>

<script>

// import axios from 'axios';

export default {
  data() {
    return {
      isBurgerMenuOpen: false,
      phoneNumber: '1133-3333',
      selectedLanguage: 'MN',
      translations: {
        login: 'Нэвтрэх',
        home: 'Үндсэн нүүр',
        location: 'Салбар байршил',
        news: 'Мэдээ, мэдээлэл',
        help: 'Тусламж',
        schedule: 'Нислэгийн хуваарь'
      }
    }
  },
  methods: {
    switchLanguage() {
      if (this.selectedLanguage === 'EN') {
        this.translations = {
          login: 'Login',
          home: 'Home',
          location: 'Location',
          news: 'News',
          help: 'Help',
          schedule: 'Flight Schedule'
        }
      } else {
        this.translations = {
          login: 'Нэвтрэх',
          home: 'Үндсэн нүүр',
          location: 'Салбар байршил',
          news: 'Мэдээ, мэдээлэл',
          help: 'Тусламж',
          schedule: 'Нислэгийн хуваарь'
        }
      }
    },
    toggleBurgerMenu() {
      this.isBurgerMenuOpen = !this.isBurgerMenuOpen; 
    },
    closeBurgerMenu() {
      this.isBurgerMenuOpen = false; 
    },
  }
  
}


</script>
<style>
@media only screen and (max-width: 1023px) {
  .burger-menu {
    position: fixed;
    top: 0;
    left: -200px;
    width: 200px;
    height: 100%;
    background-color: #ffffff;
    transition: left 0.3s ease-in-out;
    z-index: 100;
  }

  .burger-menu.open {
    left: 0;
  }
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
