<template>
  <test-header></test-header>
  <div ref="map" class="map-container mt-36"></div>
  <footer-home></footer-home>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import customMarkerImage from "@/assets/c6.svg"; // Update the path based on your project structure
import branch1Image from "@/assets/city-plaza.jpg"; // Update the path based on your project structure
import branch2Image from "@/assets/bayangol.jpg"; // Update the path based on your project structure
import branch3Image from "@/assets/kapitron.jpg";
import branch4Image from "@/assets/chingis_emart.jpg";
import branch5Image from "@/assets/han_uul_emart.jpg";
import branch6Image from "@/assets/zaisan.jpg";
import branch10Image from "@/assets/bayngol_emart.png";
import branch7Image from "@/assets/chinggis.jpg";
import branch8Image from "@/assets/ark.jpg";
import branch9Image from "@/assets/koreo1.jpg";
import TestHeader from '../components/TestHeader.vue'
import FooterHome from '../components/FooterHome.vue'


export default {
  name: "LocationMap",
  data() {
    return {
      map: null,
    };
  },
  components: {
    TestHeader,
    FooterHome,
  },

  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // Set initial map coordinates
      const initialCoordinates = [47.9149453336422, 106.91197233974552];
      const accessToken =
        "pk.eyJ1IjoiZHVsbWFhMSIsImEiOiJjbHB4cHZyODEwOGtnMmlvNWFwM29ldmw5In0.5dwJPjBdrm8VV5Kk66cf9g";

      // Create a Leaflet map
      this.map = L.map(this.$refs.map, { zoomControl: true, zoom: 1, zoomAnimation: false, fadeAnimation: true, markerZoomAnimation: true }).setView(initialCoordinates, 14);

      // Add a tile layer to the map
      L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`,
        {
          attribution: "© Mapbox, © OpenStreetMap contributors",
          tileSize: 512,
          zoomOffset: -1,
          fontSize: 45
        }
      ).addTo(this.map);

      // Example: Add a marker for a company branch
      const branches = [
        {
          name: "City Plaza",
          coordinates: [47.9149453336422, 106.91197233974552],
          description: "СБД, 1-р хороо, Сөүлийн гудамж 6А, Сити Плаза, 1 давхар. <br><br>Ажлын өдөр: 09:00-18:00 <br>Амралтын өдөр: 10:00-16:00",
          
          image: branch1Image,
        },
        {
          name: "Баянгол зочид буудал",
          coordinates: [47.91235906862353, 106.91432773347786],
          description: "СБД, 1-р хороо, Баянгол зочид буудал, Б корпус <br><br>Ажлын өдөр: 08:00-18:00 <br>Амралтын өдөр: Амарна",
          image: branch2Image,
        },
        {
          name: "Капитрон",
          coordinates: [47.91678109528277, 106.90752191164958],
          description: "ЧД, 1-р хороо, УИДэлгүүрийн зүүн талд Капитрон банк дотор <br><br>Ажлын өдөр: 09:00-17:00 <br>Амралтын өдөр: Амарна",
          image: branch3Image,
        },
        {
          name: "И-Март Сансар",
          coordinates: [47.92332453041483, 106.93387981506724],
          description: "БЗД, 1-р хороо, Токиогоийн гудамж 10, И-Март Чингис худалдааны төв, 2 давхар <br><br>Ажлын өдөр: 08:00-22:00 <br>Амралтын өдөр: 08:00-22:00",
          image: branch4Image,
        },
        {
          name: "И-Март Хан-Уул",
          coordinates: [47.90048847083325, 106.92872232609623],
          description: "ХУД, 15-р хороо, И-Март Хан-Уул худалдааны төв, 2 давхар <br><br>Ажлын өдөр: 08:00-22:00 <br>Амралтын өдөр: 08:00-22:00",
          image: branch5Image,
        },
        {
          name: "Пирамид салбар",
          coordinates: [47.914978815667304, 106.92077597971284],
          description: "СБД, 1-р хороо Эрүүл мэндийн яамны баруун урд, шилэн пирамидтай байр, Олимпын гудамж, <br><br>Ажлын өдөр: 08:00-20:00 <br>Амралтын өдөр: 10:00-16:00",
          image: branch6Image,
        },
        //  priamit iin urtrag orogrog 
        // Add more branches as needed
        {
          name: "Чингис зочид буудал",
          coordinates: [47.92226003667836, 106.9342064107865],
          description: "БЗД, 1-р хороо, Токиогоийн гудамж 10, Чингис зочид буудал, 1-р давхар <br><br>Ажлын өдөр: 08:00-18:00 <br>Амралтын өдөр: Амарна",
          image: branch7Image,
        },
        {
          name: "Арк",
          coordinates: [47.92233894702418, 106.915735373334],
          description: "СБД, 6-р хороо, Засгийн газрын ордны баруун хойно арктай байр <br><br>Ажлын өдөр: 08:00-20:00 <br>Амралтын өдөр: 10:00-16:00",
          image: branch8Image,
        },
        {
          name: "NORTH CENTER",
          coordinates: [47.92382473998773, 106.93260122625324],
          description: "БЗД, 1-р хороо, Залуучуудын өргөн чөлөө  <br><br>Ажлын өдөр: 08:30-17:30 <br>Амралтын өдөр: Амарна",
          image: branch9Image,
        },
        {
          name: "Баянгол И-Март",
          coordinates: [47.915421316155715, 106.8727040946591],
          description: "БГД, 6-р хороо, 10 хороолол  <br><br>Ажлын өдөр: 08:00-22:00 <br>Амралтын өдөр: 08:00-22:00",
          image: branch10Image,
        },
        
      ];
      branches.forEach((branch) => {
        const popupContent = `<b style="font-size: 20px;">${branch.name}</b><br><br><span style="font-size: 14px; color: black;">${branch.description}</span><br><br><img src="${branch.image}" alt="${branch.name}" width="500">`;

        const customIcon = L.icon({
          iconUrl: customMarkerImage,
          iconSize: [50, 50], // Set the size of your custom icon
          iconAnchor: [16, 32], // Set the anchor point of your custom icon
        });

        const marker = L.marker(branch.coordinates, { icon: customIcon })
          .addTo(this.map)
          .bindPopup(popupContent);
      });
    },
  },
};
</script>

<style scoped>
.map-container {
  height: 75vh;
  position: relative;
  z-index: 10;
}
</style>
