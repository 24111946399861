<template>
  <div class="absolute w-full h-full loginimg">
    <section class="">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <router-link to="./" href="#" class="flex items-center mb-16 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="" src="../assets/logo.svg" alt="logo">
        </router-link>
        <div class="w-full bg-white rounded-lg   md:mt-0 sm:max-w-md xl:p-0">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form @submit.prevent="registerUser">
              <div class="mb-4">
                <label  class="block mb-2 text-base font-medium  text-color1 text-left">Нэвтрэх нэр</label>
                <input type="text" v-model="username" placeholder="Нэр" class="border-color24 border-2 rounded-lg text-gray-900 sm:text-sm w-full p-2.5" required>
              </div>
              <div class="mb-4">
                <label  class="block mb-2 text-base font-medium  text-color1 text-left">Email</label>
                <input type="text" v-model="email" placeholder="Та И-Мэйл хаягаа оруулна уу." class="border-2 rounded-lg border-color24 text-gray-900 sm:text-sm w-full p-3" required>
              </div>
              <div class="mb-4">
                <label  class="block mb-2 text-base font-medium  text-color1 text-left">Нууц үг</label>
                <input type="password" v-model="password" placeholder="Нууц үгээ оруулна уу" class="border-2 rounded-lg text-gray-900 sm:text-sm w-full p-2.5" required>
              </div>
              <button type="submit" class="w-full mb-4 text-white bg-color2 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Бүртгүүлэх</button>
              <button type="submit" class="w-full text-white bg-color6 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Facebook эрхээрээ нэвтрэх</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
<script>
// import axios from 'axios'

export default {
  name: 'LoginRegister',
  props: {
    msg: String
  },
  data() {
    return {
      username: '',
      password: '',
      email: "",
    }
  },
  
  methods: {
    // async registerUser() {
    //   try {
    //     const response = await axios.post('https://corp.issueriss.com/api/auth/local/register/', {
    //       username: this.username,
    //       password: this.password,
    //       email: this.email,
    //     });
    //     console.log('User registered:', response.data);

    //     alert('Бүртгэл амжилттай.');
       
    //   } catch (error) {
    //     console.error('Error registering user:', error);
        
        
    //     alert('Бүртгэл амжилтгүй боллоо.');
    //   }
    // },
  },
}
</script>
  
  