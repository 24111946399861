import { createRouter, createWebHistory,  } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginHome from '../components/LoginHome.vue'
import LoginRegister from '../components/LoginRegister.vue'
import NewsHome from '../components/NewsHome.vue'
import LocationMap from '../components/LocationMap.vue'
import HelperHome from '../components/HelperHome.vue'
import Company from '../components/Company.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/loginHome',
    name: 'login',
    component: LoginHome
  },
 
  {
    path: '/LoginRegister',
    name: 'loginRegister',
    component: LoginRegister
  },
  {
    path: '/news',
    name: 'news',
    component: NewsHome
  },
  {
    path: '/location',
    name: 'location',
    component: LocationMap
  },
  {
    path: '/helper',
    name: 'helper',
    component: HelperHome
  },
  {
    path: '/Company',
    name: 'Company',
    component: Company
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router


