
import { defineComponent } from "vue";
import TestHeader from './TestHeader.vue'
import FooterHome from './FooterHome.vue'




export default defineComponent({

    data() {
        return {
            isCollapsed: 0,
        };
    },
    computed: {
    },

    components: {
        TestHeader,
        FooterHome,
    },
    setup() {
        return {

        };
    },

    name: 'HelperHome',
    props: {
        msg: String
    },




});
