<template>
  <test-header></test-header>
  <div class="z-0">

  </div>
  <div class="container mx-auto mt-48 xl:px-0 lg:px-4 md:px-4 px-4">
    <h4 class="title">
      Мэдээ мэдээлэл
    </h4>

  </div>
   <div class="container mx-auto">
    <ul v-if="!error">
      <li v-for="post in posts" :key="post.id">
        <div class="pt-2 md:pb-0 pb-0 mt-4 md:flex item-center justify-center">
          <div v-for="all in alls" :key="all.id">
            <div>
              {{ all.data.attributes.title }}
            </div>
          </div>
          
          <div class="">
            <h3 class="mb-0 md:p-10 p-4 text-3xl">{{ post.attributes.title }}</h3>
            <p class="md:pl-10 pl-4 p-4 text">{{ post.attributes.bodyMn }}</p>
          </div>
        </div>
      </li>
    </ul>
    <div v-else>
      {{ error }}
    </div>
  </div> 
  <footer-home></footer-home>
</template>

<script>
import TestHeader from './TestHeader.vue'
import FooterHome from './FooterHome.vue'
import axios from 'axios';




export default {
  data() {
    return {
      posts: "",
      img: "",
      error: null
    };
  },
  components: {
    TestHeader,
    FooterHome,
  },

  async mounted() {
    try {
      const response = await axios.get('https://corp.issueriss.com/api/posts', {
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE3NDAyMjY5LCJleHAiOjE3MTk5OTQyNjl9.4TIVoxFWTXtSs_WpvBSPPTVj4aaFOHfDg7eEkg43m0Q',
        },
      });
      const response2 = await axios.get('https://corp.issueriss.com/api/posts?populate', {
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE3NDAyMjY5LCJleHAiOjE3MTk5OTQyNjl9.4TIVoxFWTXtSs_WpvBSPPTVj4aaFOHfDg7eEkg43m0Q',
        },
      });
      this.all = response2.data.data;
      this.posts = response.data.data;
    } catch (error) {
      this.error = `Error loading data. Status: ${error.response ? error.response.status : 'Unknown'}`;
      console.error('Error loading data:', {
        message: error.message,
        response: error.response ? error.response.data : null,
        request: error.request,
      });
    }
  }

}
</script>





<style>
.text {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-size: 16px;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 36px;

}
</style>
