
import { defineComponent } from "vue";
import TestHeader from './TestHeader.vue'
import FooterHome from './FooterHome.vue'




export default defineComponent({

  data() {
    return {
      isCollapsed: 0,

    };
  },
  computed: {

  },


  components: {
    TestHeader,
    FooterHome,
  },
  setup() {
    return {

    };
  },

  name: 'HelperHome',
  props: {
    msg: String
  },

  methods: {
    toggleCollapse(section: number) {
      if (this.isCollapsed === section) {
        this.isCollapsed = 0; // Clicking on an already open section closes it
      } else {
        this.isCollapsed = section; // Clicking on a closed section opens it
      }
    }
  },


});
